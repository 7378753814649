@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
  user-select: none;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none;
}

* {
  -ms-overflow-style: none;
  color: #fff;
}
h2 {
  line-height: initial !important;
}
p {
  /* line-height: 22px; */
  font-weight: 600;
}
::-webkit-scrollbar {
  display: none;
}

p {
  font-weight: 400;
  font-size: 12px;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}

body {
  /* min-height: 100vh; */
  color: rgb(var(--foreground-rgb));
  background: linear-gradient(
      to bottom,
      transparent,
      rgb(var(--background-end-rgb))
    )
    rgb(var(--background-start-rgb));
}

/* For routes rendering pdf */
.wrap {
  padding: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.controls {
  margin: 20px 0px;
  display: flex;
  gap: 40px;
}
.controls button {
  padding: 6px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  cursor: pointer;
  background: white;
}
.controls button:disabled {
  background: gray;
  cursor: not-allowed;
}
.controls button .prev {
  transform: rotate(180deg);
}
.controls button img {
  height: 20px;
  width: 20px;
}

.react-pdf__Page__textContent {
  user-select: text;
}
